.results {
  h1 {
    span {
      display: block;
    }
  }

  .title {
    h4 {
      text-align: left;
    }
  }

  .image-cropper {
    position: relative;
    overflow: hidden;
    border-radius: 10px;

    &__big {
      aspect-ratio: 1 / 1;
    }
    &__small {
      width: 60px;
      min-width: 60px;
      height: 60px;
    }

    .badges {
      position: absolute;
      bottom: 15px;
      left: 0;
      width: 100%;
      text-align: center;

      .badge {
        background-color: rgba($white, 0.8);
        width: 25%;
        margin: 0 10px;
        padding: 15px 10px 15px 10px;

        @include media-breakpoint-down(md) {
          padding: 15px 5px 15px 5px;
          margin: 0 3px;
        }

        p {
          color: #00427a;
          font-weight: bold;
          margin-bottom: 0;
        }
        p.lead {
          color: $blue;
          margin: 5px 0;
        }
      }
    }
  }
  .result-pic {
    display: inline;
    margin: 0 auto;
    //margin-left: -25%; //centers the image
    height: 100%;
    width: 100%;
  }

  .related {
    padding: 15px;
    background-color: rgba($white, 0.8);
    border-radius: 15px;
    margin-bottom: 10px;
    a {
      text-decoration: none;
    }
    p {
      color: #7c909f;
    }

    p.lead {
      color: #00427a;
    }
  }

  table {
    text-align: left;
    td,
    th {
      border: 0;
      vertical-align: middle;
      padding-left: 0;
    }

    .icons {
      img {
        width: 55px;
        padding: 0 5px;
        @include media-breakpoint-down(md) {
          width: 30px;
        }
      }
    }
  }
  .progress {
    position: relative;
    .progress-text {
      line-height: 1;
      position: absolute;
      top: 50%;
      left: 10px;
      transform: translateY(-50%);
    }
  }
}

.idealno {
  span {
    display: inline-block;
    position: relative;
    padding-right: 30px;
    padding-left: 30px;
    &:before {
      content: '';
      display: inline-block;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      width: 20px;
      height: 20px;
      margin-right: 5px;
      background-image: url('/assets/images/kam-pa-kam/icons/icon-check.svg');
      background-repeat: no-repeat;
      background-size: contain;
    }
  }
}

.react-tooltip {
  width: 200px !important;
}
