.quiz-intro {
  background-image: url(/assets/images/bg.jpg);

  @include media-breakpoint-down(sm) {
    background-image: url(/assets/images/bg-mobile.jpg);
  }

  .h1-welcome {
    letter-spacing: 0.02em;
    line-height: 1.2;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25),
      0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-size: 3.5rem;
    @include media-breakpoint-down(sm) {
      font-size: 2rem;
    }

    span {
      font-family: $font-family-sans-serif;
      display: block;
      font-size: 1.1rem;
      color: #f3b901;

      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
      @include media-breakpoint-down(sm) {
        font-size: 0.9rem;
      }
    }
  }
  .usps {
    p {
      @include media-breakpoint-down(sm) {
        font-size: 0.7rem;
      }
    }

    img {
      @include media-breakpoint-down(sm) {
        height: 50px;
      }
    }
  }

  .bottom-wrapper {
    position: absolute;
    top: 45%;
    @include media-breakpoint-down(sm) {
      top: auto;
      bottom: 15%;
    }
  }
}
