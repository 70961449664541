@import url('https://cdn.jsdelivr.net/npm/bootstrap-icons@1.9.1/font/bootstrap-icons.css');

@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Chewy&family=Lato:wght@400;700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Kalam:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

body {
  box-sizing: border-box;
  color: $white;
  font-family: 'Roboto', sans-serif;
  margin: 0;
  overflow-x: hidden;
  padding: 0;
}

body,
html,
#root {
  min-height: 100vh;
  background-color: #004d8c;
}

.quiz {
  min-height: 100vh;
  margin: 0;
  max-width: 100vw;
  padding: 0;
  position: relative;
  overflow: hidden;

  .wrapper {
    min-height: 100vh;
    background-position: center top, center top;
    background-repeat: no-repeat, no-repeat;
    background-size: 100%, 100%;
    transition: background-image 0.2s ease-in-out;
    background-image: url(/assets/images/kam-pa-kam/BG-over.png),
      url(/assets/images/kam-pa-kam/BG-under.jpg);
    //background-color: #0066b3;

    @include media-breakpoint-down(md) {
      background-image: url(/assets/images/kam-pa-kam/BG-over-mobile.png),
        url(/assets/images/kam-pa-kam/BG-under.jpg);
      background-position: center bottom, center top;
      background-size: 100%, 300%;
    }

    &.quiz-intro {
      @include media-breakpoint-down(md) {
        background-image: url(/assets/images/kam-pa-kam/BG-over-mobile.png),
          url(/assets/images/kam-pa-kam/BG-under.jpg);
        background-position: center 40px, center top;
        background-size: 100%, 300%;
      }
    }

    .inner-wrapper {
      //background-color: $black;
      width: 800px;
      @include media-breakpoint-down(lg) {
        width: 100%;
      }

      &__wide {
        width: 1200px;
        @include media-breakpoint-down(lg) {
          width: 100%;
        }
      }
    }

    &.quiz-questions,
    &.results {
      background-image: url(/assets/images/kam-pa-kam/BG-sub.svg);
      background-size: cover;
    }
  }
}

// Common

// Typography

.lead {
  font-weight: 400;
  @include media-breakpoint-down(sm) {
    font-size: 0.85rem;
  }
}

h4 {
  font-family: 'Roboto', sans-serif;
}

// Buttons

.btn {
  text-transform: uppercase;
  font-weight: 900;
}

.btn:focus {
  box-shadow: none;
  outline: none;
}

.btn-back {
  border: none;
  color: $gray;
  cursor: pointer;
  display: block;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  margin-left: auto !important;
  margin-right: auto !important;
  opacity: 0.85;
  outline: none;
  position: relative;
  margin-top: 50px;

  text-align: center;

  .bi-arrow-left {
    font-size: 20px;
    position: relative;
    top: 2px;
  }
}

// Header

.logo {
  position: absolute;
  right: 20px;
  top: 20px;
  @include media-breakpoint-down(sm) {
    right: 10px;
    top: 10px;
    width: 45px;
  }
}

.header-logo {
  position: fixed;
  top: 0;
  right: -20px;
  img {
    @include media-breakpoint-down(sm) {
      width: 130px;
    }
  }
}

// Footer

.footer-logo {
  //opacity: .35;
  position: absolute;
  bottom: 0;

  img {
    width: 100px;
  }

  &__left {
    left: 0;
  }

  &__right {
    right: 0;
    img {
      width: 120px;
    }
  }
}

// Loader

.loader {
  @include media-breakpoint-down(sm) {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  svg {
    width: 230px !important;
    height: 230px !important;
  }
}

/*********** 
For Dev 
***********/

// Show filtered products

.dev-filtered-products {
  background-color: rgb(4, 224, 177);
  bottom: 0;
  display: none;
  left: 0;
  max-height: 200px;
  overflow: scroll;

  padding: 5px;
  position: fixed;
  width: 100%;

  span {
    color: #000;
    display: inline-block;
    font-size: 12px;
    padding: 5px 10px;
  }
}
