.quiz-questions {
  .inner-wrapper {
    height: 100%;
  }

  .btn-question {
    align-items: center;
    display: inline-flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 0 10px;
    transition: 1s;
    text-transform: none;

    &.image-answer {
      border: 0;
      outline: none;

      @include media-breakpoint-down(sm) {
        width: 50%;
      }

      &:hover {
        > div {
          opacity: 0.9;
        }
      }

      &:active,
      &:focus {
        > div {
          //background-color: #58b947;
          filter: brightness(75%);
          transition: 1s;
        }
      }

      > div {
        background-color: #ffc20e;
        border: none;
        border-radius: 100%;
        height: 115px;
        overflow: hidden;
        width: 115px;
        img {
          width: 100%;
        }
        @include media-breakpoint-down(sm) {
          width: 80px;
          height: 80px;
        }
      }

      > p {
        width: 115px;

        @include media-breakpoint-down(sm) {
          font-size: 0.8rem;
          width: 90px;
        }
      }

      .jpg {
        width: 200px;
        height: 200px;
        @include media-breakpoint-down(sm) {
          width: 120px;
          height: 120px;
        }
        img {
          height: 100%;
        }
      }
    }

    &.text-answer {
      background-color: $yellow;
      border: none;
      border: none;
      border-radius: 20px;
      color: $black;
      display: flex;
      flex-direction: column !important;
      font-size: 0.9rem;
      min-height: 40px;
      margin: 0 auto;
      text-align: center;
      width: 425px;
      line-height: 1.2;
      padding: 9px 10px;
      color: $white;

      &:active,
      &:focus {
        filter: brightness(75%);
      }
      &:hover {
        opacity: 0.9 !important;
      }
      @include media-breakpoint-down(sm) {
        width: 100%;
      }
    }
  }

  .image-question-answer-wrapper {
    //display: inline-flex;
    //justify-content: space-around;
    //flex-flow: column;
    @include media-breakpoint-up(md) {
      //flex-flow: row;
    }
  }

  .image-question-answer-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    @include media-breakpoint-down(md) {
      display: block;
    }
  }

  .image-answer-wrapper {
    flex: 1 1;

    @include media-breakpoint-down(md) {
      display: inline-block;
      vertical-align: top;
    }
  }

  .note {
    border: 2px solid $white;
    border-radius: 20px;
    color: $white;
    max-width: 450px;
    padding: 10px;
  }
}
