.step-progress-bar {
  align-items: flex-end;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;

  text-align: center;
  width: 140px;

  @include media-breakpoint-up(md) {
    position: absolute;
    left: 50%;
    top: 100px;
    transform: translateX(-50%);
  }

  &::after {
    background: $yellow;
    bottom: 5.75px;
    content: ' ';
    height: 0.7px;
    position: absolute;
    width: 100%;
    z-index: 1;
  }
  .stepBullet {
    background: #d9d9d9;
    border-radius: 100%;
    height: 12.65px;
    list-style: none;
    padding-left: 0;
    width: 12.65px;
    z-index: 2;

    &.done {
      background: $yellow;
    }

    &.active {
      background: $yellow;
      box-shadow: 0px 0px 8px 3px rgba($yellow, 0.4);
    }
  }
}
